import {
  API_PATH_API as API,
  API_PARAM_INTERNAL_STUDENT_ID as INTERNAL_STUDENT_ID,
  API_PARAM_LIMIT as LIMIT,
  API_PATH_SCHOOLS as SCHOOLS,
  API_PATH_SCHOOL_YEARS as SCHOOL_YEARS,
  API_PARAM_SEARCH_TEXT as SEARCH_TEXT,
  API_PATH_SIS as SIS,
  API_PATH_STUDENT as STUDENT,
  API_PATH_STUDENTS as STUDENTS,
  API_PATH_TENANT as TENANT,
  API_PATH_USER as USER,
  API_PATH_USERS as USERS,
  API_PARAM_USER_ID as USER_ID
} from '@ps-monorepo/api-constants';
import {
  GridWidgetRestArgParams,
  QueryParams,
  SchoolPage,
  SchoolYearPage,
  Student,
  StudentsPage,
  Tenant,
  User
} from '@ps-monorepo/api-models';
import httpClient from '../utilities/http-client';

/**
 * Service for access to SIS data via REST APIs
 */
export class SISService {
  /**
   * Get info about the tenant, including current school year and state/country codes
   */
  public static async getTenant(): Promise<Tenant> {
    // GET request to API
    const { data } = await httpClient.get(`/${API}/${SIS}/${TENANT}`);
    return data;
  }

  /**
   * Get info about the current user, or else a specific user if user identifier is specified
   *
   * @param userId
   */
  public static async getUser(userId?: string): Promise<User> {
    // Request config (ex. params)
    let config = {};

    // Add user identifier to query string params if provided
    if (userId) {
      // Parameter names must be consistent with server side
      const params = { [USER_ID]: userId };
      config = { params };
    }

    // Get user
    const { data } = await httpClient.get(`/${API}/${SIS}/${USER}`, config);
    return data;
  }

  /**
   * Get list of users for the tenant/district
   *
   * @param searchText
   * @param limit
   * @param userId
   */
  public static async getUsers(
    searchText: string,
    limit: number,
    userId?: string
  ): Promise<Array<User>> {
    // Parameter names must be consistent with server side
    const params = {
      [SEARCH_TEXT]: searchText,
      [LIMIT]: limit,
      [USER_ID]: userId
    };
    const { data } = await httpClient.get(`/${API}/${SIS}/${USERS}`, { params });
    return data;
  }

  /**
   * Get data about schools in the tenant's district
   */
  public static async getSchools(): Promise<SchoolPage> {
    // GET request to API
    const { data } = await httpClient.get(`/${API}/${SIS}/${SCHOOLS}`);
    return data;
  }

  /**
   * Get data about school years in the tenant's district
   */
  public static async getSchoolYears(): Promise<SchoolYearPage> {
    // GET request to API
    const { data } = await httpClient.get(`/${API}/${SIS}/${SCHOOL_YEARS}`);
    return data;
  }

  /**
   * Get student by internal identifier
   *
   * @param internalStudentId
   */
  public static async getStudent(internalStudentId: string): Promise<Student> {
    // Parameter names must be consistent with server side
    const config = { params: { [INTERNAL_STUDENT_ID]: internalStudentId } };

    // GET request to API
    const { data } = await httpClient.get(`/${API}/${SIS}/${STUDENT}`, config);
    return data;
  }

  /**
   * Get a filtered set of students
   *
   * @param filterArgs
   * @param sortArgs
   * @param pageSize
   * @param pageNumber
   * @param returnCount
   */
  public static async getStudents(
    filterArgs: GridWidgetRestArgParams[],
    sortArgs: GridWidgetRestArgParams[],
    pageSize: number,
    pageNumber: number,
    returnCount: boolean
  ): Promise<StudentsPage> {
    // Body structure must be consistent with server side
    const body: QueryParams = {
      pageSize,
      pageNumber,
      filterArgs,
      sortArgs,
      returnCount
    };

    // POST request to API to get student data
    const { data } = await httpClient.post(`/${API}/${SIS}/${STUDENTS}`, body);
    return data;
  }
}
