import { API_PATH_API as API, API_PATH_LOGOUT as LOGOUT } from '@ps-monorepo/api-constants';
import httpClient from '../utilities/http-client';

/**
 * Service for managing server-side session
 */
export class SessionService {
  /**
   * Log out of authenticated server-side session
   *
   * @param logMessage
   */
  public static async logout(logMessage: string): Promise<void> {
    // POST logout (server-side will handle invalidation of auth user session)
    try {
      await httpClient.post(`/${API}/${LOGOUT}`);

      // Log for analysis
      console.debug(logMessage);
    } catch (error) {
      console.error('Error while signing out', error);
    }
  }
}
