// Used as ID values in the student grid widget DOM
export const STUDENT_ID = 'addressed-student-id';
export const STUDENT_NAME = 'last-name';
export const REQUEST_CODE = 'request-code';
export const REQ_INSTITUTION = 'requesting-institution';
export const RECORD_TYPE = 'request-type';
export const ADDR_INSTITUTION = 'addressed-institution';
export const REQ_DATE = 'when-created';
export const STATUS = 'status';
export const LAST_UPDATED = 'when-modified';
export const REQUESTED_BY = 'who-created';
export const RESPONSE_CODE = 'response-code';

export const SORT_STUDENT_ID = `sort-${STUDENT_ID}`;
export const SORT_STUDENT_NAME = `sort-${STUDENT_NAME}`;
export const SORT_REQUEST_CODE = `sort-${REQUEST_CODE}`;
export const SORT_REQ_INSTITUTION = `sort-${REQ_INSTITUTION}`;
export const SORT_RECORD_TYPE = `sort-${RECORD_TYPE}`;
export const SORT_ADDR_INSTITUTION = `sort-${ADDR_INSTITUTION}`;
export const SORT_REQ_DATE = `sort-${REQ_DATE}`;
export const SORT_STATUS = `sort-${STATUS}`;
export const SORT_LAST_UPDATED = `sort-${LAST_UPDATED}`;
export const SORT_REQUESTED_BY = `sort-${REQUESTED_BY}`;
export const SORT_RESPONSE_CODE = `sort-${RESPONSE_CODE}`;
