import {
  API_PATH_API as API,
  API_PARAM_CODE_TYPE,
  API_PARAM_COUNTRY,
  API_PARAM_DESCRIPTION_LENGTH_LIMIT,
  API_PARAM_REQUEST_ID,
  API_PARAM_STATE,
  API_PARAM_STX_CODES_LIMIT,
  API_PARAM_TRANSCRIPT_EXCHANGE_ID,
  API_STX_DEFAULT_DESC_LIMIT,
  API_STX_DEFAULT_LIMIT,
  AllStateCodes,
  API_PATH_CODES as CODES,
  CountryCodes,
  API_PATH_INCOMING as INCOMING,
  API_PATH_OUTGOING as OUTGOING,
  API_PATH_PDF as PDF,
  API_PATH_REQUEST as REQUEST,
  API_PATH_REQUESTS as REQUESTS,
  API_PATH_REQUEST_TYPES as REQUEST_TYPES,
  API_PATH_STATUS as STATUS,
  API_PATH_STX as STX
} from '@ps-monorepo/api-constants';
import {
  GridWidgetRestArgParams,
  LabelStateOverride,
  STXCodeSet,
  STXIncomingRequestDetailsPage,
  STXIncomingRequestDisposition,
  STXOutgoingRequestDetailsPage,
  STXStatus,
  Student
} from '@ps-monorepo/api-models';
import httpClient from '../utilities/http-client';

/**
 * Service for access to Student Transcript Exchange (STX) data via REST APIs
 */
export class STXService {
  /**
   * Create new STX outgoing request
   *
   * @param data
   * @param requestingInstitutionCode
   * @param requestingInstitutionName
   * @param addressedInstitutionCode
   * @param addressedInstitutionName
   * @param requestCode
   * @param requestType
   */
  public static async postSTXOutgoingRequest(
    data: Array<Student>,
    requestingInstitutionCode: string,
    requestingInstitutionName: string,
    addressedInstitutionCode: string,
    addressedInstitutionName: string,
    requestCode: string,
    requestType: string
  ): Promise<void> {
    // Create JSON body to send
    const body = JSON.stringify({
      data,
      requestingInstitutionCode,
      requestingInstitution: requestingInstitutionName,
      addressedInstitutionCode,
      addressedInstitution: addressedInstitutionName,
      requestCode,
      requestType
    });
    // POST request to API
    await httpClient.post(`/${API}/${STX}/${REQUEST}/${OUTGOING}`, body);
  }

  /**
   * Get STX status
   */
  public static async getSTXStatus(): Promise<STXStatus & LabelStateOverride> {
    // GET request to API
    const { data } = await httpClient.get(`/${API}/${STX}/${STATUS}`);
    return data;
  }

  /**
   * Set disposition of STX incoming request as either approved, rejected or assigned
   *
   * @param incomingRequestDisposition
   */
  public static async putSTXIncomingRequest(
    incomingRequestDisposition: STXIncomingRequestDisposition
  ): Promise<void> {
    try {
      // Use a PUT since we do not intend to create a new record whenever sending for the same
      // parameters, only updating an existing record
      await httpClient.put(`/${API}/${STX}/${REQUEST}/${INCOMING}`, incomingRequestDisposition);
    } catch (error) {
      console.error('Error while posting STX incoming request match', error);
    }
  }

  /**
   * Get set of STX outgoing request details
   *
   * @param body
   */
  public static async getSTXOutgoingRequestDetailsPage(body: {
    pageSize: number,
    pageNumber: number,
    sortArgs: GridWidgetRestArgParams[],
    filterArgs: GridWidgetRestArgParams[],
    schoolSelection: string
  }): Promise<STXOutgoingRequestDetailsPage> {
    // Use a POST since we need to pass parameters in body in order to avoid possible sensitive
    // data being recorded in access logs
    const { data } = await httpClient.post(`/${API}/${STX}/${REQUESTS}/${OUTGOING}`, body);
    return data;
  }

  /**
   * Get STX outgoing request pdf data
   *
   * @param requestId
   * @param transcriptExchangeId
   */
  public static async getOutgoingRequestPdf(
    requestId: string,
    transcriptExchangeId: string
  ): Promise<void> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_REQUEST_ID]: requestId,
      [API_PARAM_TRANSCRIPT_EXCHANGE_ID]: transcriptExchangeId
    };

    try {
      // GET request to API
      const { data } = await httpClient.get(
        `/${API}/${STX}/${REQUEST}/${OUTGOING}/${PDF}`,
        { params }
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Get set of STX incoming request details
   *
   * @param body
   */
  public static async getSTXIncomingRequestDetailsPage(body: {
    pageSize: number,
    pageNumber: number,
    sortArgs: GridWidgetRestArgParams[],
    filterArgs: GridWidgetRestArgParams[],
    schoolSelection: string
  }): Promise<STXIncomingRequestDetailsPage> {
    // Use a POST since we need to pass parameters in body in order to avoid possible sensitive
    // data being recorded in access logs
    const { data } = await httpClient.post(`/${API}/${STX}/${REQUESTS}/${INCOMING}`, body);
    return data;
  }

  /**
   * Get STX Code Sets for a specific country and state
   *
   * @param {CountryCodes} country
   * @param {AllStateCodes} state
   * @param {number} limit
   * @param {number} descriptionLengthLimit
   * @param {string} codeType
   * @returns
   */
  public static async getSTXCodes(
    country: CountryCodes,
    state: AllStateCodes,
    limit?: number,
    descriptionLengthLimit?: number,
    codeType?: string
  ): Promise<STXCodeSet> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_COUNTRY]: country,
      [API_PARAM_STATE]: state,
      [API_PARAM_STX_CODES_LIMIT]: limit || API_STX_DEFAULT_LIMIT,
      [API_PARAM_DESCRIPTION_LENGTH_LIMIT]: descriptionLengthLimit || API_STX_DEFAULT_DESC_LIMIT,
      [API_PARAM_CODE_TYPE]: codeType
    };

    // Get request to API
    const { data } = await httpClient.get(`/${API}/${STX}/${CODES}`, { params });
    return data;
  }

  /**
   * Get STX Code Sets for a specific country and state.
   *
   * @param {CountryCodes} country
   * @param {AllStateCodes} state
   * @param {number} limit
   * @returns
   */
  public static async getSTXRequestTypes(
    country: CountryCodes,
    state: AllStateCodes,
    limit?: number
  ): Promise<STXCodeSet> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_COUNTRY]: country,
      [API_PARAM_STATE]: state,
      [API_PARAM_STX_CODES_LIMIT]: limit || API_STX_DEFAULT_LIMIT
    };

    // GET request to API
    const { data } = await httpClient.get(`/${API}/${STX}/${REQUEST_TYPES}`, { params });
    return data;
  }
}
