import {
  SECONDARY_CONFIG_WEB_SOCKET_ROUTE,
  SECONDARY_LOG_WEB_SOCKET_ROUTE,
  SFR_CONFIG_WEB_SOCKET_ROUTE,
  SFR_LOG_WEB_SOCKET_ROUTE,
  STX_REQUESTS_WEB_SOCKET_ROUTE
} from '@ps-monorepo/api-constants';
import { Tenant, User, WebSocketMessage } from '@ps-monorepo/api-models';
import history from 'history/browser';
import { PdsAppNav, PdsUserMenu } from 'powerschool-design-system/react';
import { NavItem } from 'powerschool-design-system/tool-kit';
import React, { ReactNode } from 'react';
import {
  Link,
  NavigateFunction,
  BrowserRouter as Router,
  useNavigate
} from 'react-router-dom';
import AppRoutes from './components/app-routes';
import { LoggedOut } from './components/logged-out';
import {
  RTE_DASHBOARD,
  RTE_DEMO_FASTER,
  RTE_DEMO_SSE,
  RTE_DEMO_TYPE_AHEAD_WIDGET,
  RTE_SECONDARY_REPORTS,
  RTE_SFR,
  RTE_SFR_PRIOR_YRS,
  RTE_STX
} from './const';
import {
  HandleError,
  NavigateToUrl,
  STXWidgetProps,
  UpdateSFRConfig,
  UpdateSFRLog,
  UpdateSecondaryConfig,
  UpdateSecondaryLog
} from './models';
import { SessionService } from './services/session-service';
import { SISService } from './services/sis-service';
import { STXService } from './services/stx-service';
import { delaySec } from './utilities/misc';
import { pdsMessageService } from './utilities/pds';
import { generateWebSocketMessage } from './utilities/web-socket-util';

// Indicates that these are declared somewhere outside these typescript files
declare const MODE;
declare const LOCAL;
declare const WEB_SOCKET_PROTOCOL;
declare const WEB_SOCKET_PATH;

// Define small Props and State in component file to reduce number of unnecessary model files
type Props = {
  navigate: NavigateFunction;
};
type State = {
  isLoggedIn: boolean;
  isShowingAsLoggedIn: boolean;
  currentTenant: Tenant;
  currentUser: User;
  navigationData: Array<NavItem>;
  userNavigationData: Array<NavItem>;
  updateSTXRequestsCounter: number;
} & NavigateToUrl & STXWidgetProps & UpdateSFRConfig & UpdateSFRLog & UpdateSecondaryConfig
  & UpdateSecondaryLog;

/**
 * Main base component without access to navigation (useNavigate) by itself
 */
export class MainBase extends React.Component<Props, State> implements HandleError {
  // HTML element ID for Dashboard menu item
  public static NAV_LINK_ID_DASHBOARD = 'nav-link-dashboard';

  // HTML element ID for STX (FASTER) menu item
  public static NAV_LINK_ID_STX = 'nav-link-stx';

  // HTML element ID for Prior Reports menu item
  public static NAV_LINK_ID_PRIOR_YEAR_REPORTS = 'nav-link-prior-year-reports';

  // HTML element ID for SFR menu item
  public static NAV_LINK_ID_SFR = 'nav-link-sfr';

  // HTML element ID for Secondary Reporting menu item
  public static NAV_LINK_ID_SECONDARY_REPORTS = 'nav-link-secondary';

  // HTML element ID for logout menu item (user menu)
  public static USER_NAV_LINK_ID_LOGOUT = 'user-nav-link-logout';

  /**
   * Web Socket client (built-in Javascript construct)
   */
  webSocket: WebSocket;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // Destructuring to get navigate function
    const { navigate } = this.props;

    // Function that navigates the main view to the given URL, updates browser history and ensures
    // that proper left nav link item is highlighted
    const navigateToUrl = (url: string, options?: { state: any }) => {
      // Navigate to the selected route, passing any valid options
      navigate(url, options);

      // Also replace history as this is used to remember path on page reload
      history.replace(url);

      // Need to invoke the setHighlights on the PDS App Nav object to ensure proper link item is
      // highlighted in case navigation happened in alternate way to user clicking on left nav menu
      // Note: this is not the ReactJS way to get to the PDS App Nav object, but that would require
      //       changes in PDS, so this saves a load of dev time
      (document.querySelector('pds-app-nav') as any)?.setHighlights();
    };

    // Function used in PDS App Nav items that utilizes navigate function above
    const onUserClick = (navItem: NavItem) => {
      // Navigate to URL and track in browser history
      navigateToUrl(navItem.url);
    };

    // Used to ensure proper left nav item is highlighted in case user navigates in alternate way
    // from simply clicking on left nav items
    const isCurrentRoute = (navItem: NavItem): boolean => window.location?.pathname === navItem.url;

    // Standard navigation menu items
    const navigationData = [
      {
        id: MainBase.NAV_LINK_ID_DASHBOARD,
        name: 'Dashboard',
        iconName: 'dashboard',
        url: RTE_DASHBOARD,
        onUserClick,
        isCurrentRoute
      },
      {
        id: MainBase.NAV_LINK_ID_SFR,
        name: 'State and Federal Reporting',
        iconName: 'state',
        url: RTE_SFR,
        onUserClick,
        isCurrentRoute
      },
      {
        id: MainBase.NAV_LINK_ID_PRIOR_YEAR_REPORTS,
        name: 'Prior Year Reports',
        iconName: 'documents',
        url: RTE_SFR_PRIOR_YRS,
        onUserClick,
        isCurrentRoute
      },
      {
        id: MainBase.NAV_LINK_ID_SECONDARY_REPORTS,
        name: 'Secondary Reporting',
        iconName: 'clipboard-checkmark-empty',
        url: RTE_SECONDARY_REPORTS,
        onUserClick,
        isCurrentRoute
      },
      {
        id: MainBase.NAV_LINK_ID_STX,
        name: 'FASTER',
        iconName: 'user-desk',
        url: RTE_STX,
        onUserClick,
        isCurrentRoute
      }
    ];

    // If in development mode, add some additional navigation menu items for demo components
    if (MODE === 'development') {
      // Demo FASTER workflows
      navigationData.push({
        id: 'pscc-demo-faster',
        name: 'Demo FASTER',
        iconName: 'rocket',
        url: RTE_DEMO_FASTER,
        onUserClick,
        isCurrentRoute
      });
      // Type ahead widget demo
      navigationData.push({
        id: 'pscc-demo-type-ahead-widget',
        name: 'Demo Type Ahead Widget',
        iconName: 'text-entry',
        url: RTE_DEMO_TYPE_AHEAD_WIDGET,
        onUserClick,
        isCurrentRoute
      });
    }

    // Only add the SSE demo component if running locally (unsupported in AWS)
    if (LOCAL) {
      navigationData.push({
        id: 'pscc-demo-sse',
        name: 'Demo SSE (Local Only)',
        iconName: 'clock',
        url: RTE_DEMO_SSE,
        onUserClick,
        isCurrentRoute
      });
    }

    // Initialize the state in the constructor
    this.state = {
      isLoggedIn: true, // Upon first landing, assume user is logged in
      isShowingAsLoggedIn: true, // Display page as if user is logged in
      currentTenant: null,
      currentUser: null, // Must be init to null so polymer-based PdsUserMenu works properly
      navigateToUrl,
      navigationData,
      userNavigationData: [
        {
          id: MainBase.USER_NAV_LINK_ID_LOGOUT,
          name: 'Sign Out',
          iconName: 'logout',
          onUserClick: (/* navItem: navItem, event: Event */) => {
            // Set user session state as logged out on client
            this.setAsLoggedOut();

            // Close web socket connection first before server-side log out
            this.closeWebSocket();

            // Server-side log out (but don't wait here on request)
            SessionService.logout('User signed out');

            // Display the logged out page
            this.showAsLoggedOut();
          }
        }
      ],
      // These have been lifted upward from STX widget
      stxStatus: {
        incomingPendingCount: 0,
        incomingProgressCount: 0,
        incomingCompletedCount: 0,
        outgoingPendingCount: 0,
        outgoingProgressCount: 0,
        outgoingCompletedCount: 0
      },
      stxStateWidgetLabel: '',
      // Used to signal to child components that has been updated on the server
      updateSTXRequestsCounter: 0,
      updateSFRConfigCounter: 0,
      updateSFRLogCounter: 0,
      updateSecondaryConfigCounter: 0,
      updateSecondaryLogCounter: 0
    };
  }

  /**
   * This is a life-cycle method inherent in React. Mounting means that element has been put into
   * DOM.
   */
  async componentDidMount() {
    // Destructuring to get navigate function
    const { navigate } = this.props;

    // Collect promises to load tenant, user STX status
    const loadTenantPromise = this.loadTenant();
    const loadUserPromise = this.loadUser();
    const loadSTXStatusPromise = this.loadSTXStatus();
    const openWebSocketPromise = this.openWebSocket();

    // Execute all promises in parallel
    // Note: no need to check for errors since each of the functions handle their own
    await Promise.allSettled([
      loadTenantPromise,
      loadUserPromise,
      loadSTXStatusPromise,
      openWebSocketPromise
    ]);

    // Set initial route selection from history if it is there, otherwise got to home
    // Note: The "navigate" property maps to the useNavigate hook
    try {
      navigate(
        history.location && history.location.pathname
          ? history.location.pathname : RTE_DASHBOARD
      );
    } catch (error) {
      // Not high priority enough to alert user, so we just use console.error here
      console.error('Error trying to navigate', error);
    }
  }

  /**
   * This is a life-cycle method inherent in React. This method allows reaction to updates in state
   * or props values.
   *
   * @param prevProps
   * @param prevState
   */
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    // Destructure previous state and current state
    const { updateSTXRequestsCounter: stxRequestsPrev } = prevState;
    const { updateSTXRequestsCounter: stxRequestsCurr } = this.state;

    // If there was an update to STX Requests table, reload the STX status
    if (stxRequestsPrev !== stxRequestsCurr) {
      await this.loadSTXStatus();
    }
  }

  /**
   * This is a life-cycle method inherent in React. Unmounting means that element will be removed
   * from DOM.
   */
  componentWillUnmount() {
    // Because of the page refresh scenario (F5 key), we should not log the user out automatically,
    // though it is okay to close the web socket connection since it should re-open upon page
    // refresh
    this.closeWebSocket();
  }

  /**
   * Boilerplate for handling an error
   *
   * @param message
   * @param error
   */
  handleError = (message: string, error: any) => {
    console.error(message, error);

    // If error is defined, get handle on response
    const response = error ? error.response as Response : undefined;

    // In case of a 401, logout
    if (response && response.status === 401) {
      // Set user session state as logged out on client
      this.setAsLoggedOut();

      // Close web socket connection first before signing out
      this.closeWebSocket();

      // Upon 401 Unauthorized error, do server-side log out (but don't wait here on request)
      SessionService.logout('Unauthorized error - user has been signed out');

      // Display the logged out page
      this.showAsLoggedOut();
    } else {
      try {
        pdsMessageService.displayMessage('ERROR', 'FLOATING', message);
      } catch (error2) {
        // Not high priority enough to alert user, so we just use console.error here
        console.error('Error trying to display message', error2);
      }
    }
  };

  /**
   * Boilerplate for handling an success message
   *
   * @param message
   */
  handleSuccess = (message: string) => { // eslint-disable-line class-methods-use-this
    try {
      pdsMessageService.displayMessage('SUCCESS', 'FLOATING', message);
    } catch (error) {
      // Not high priority enough to alert user, so we just use console.error here
      console.error('Error trying to display message', error);
    }
  };

  /**
   * Get the current user session state
   */
  getLoggedIn = () => {
    const { isLoggedIn } = this.state;
    return isLoggedIn;
  };

  /**
   * Set user session state as logged out on client
   */
  setAsLoggedOut() {
    const isLoggedIn = false;
    this.setState({ isLoggedIn });
  }

  /**
   * Open web socket connection to server
   */
  openWebSocket = async () => {
    // Handle to this same function for use in web socket handler functions
    const openWebSocketLocal = this.openWebSocket;

    // Handle to clear web socket function for use in web socket handler functions
    const clearWebSocketLocal = this.clearWebSocket;

    // Handle to get logged in function for use in web socket handler functions
    const getLoggedInLocal = this.getLoggedIn;

    // Initial delay between retries
    let initDelaySec = 1;

    // Max delay between retries
    const maxDelaySec = 5;

    // Flag used to indicate if a 401 Unauthorized was encountered
    let unauthorizedError = false;

    // Keep trying to establish web socket connection as long as a 401 Unauthorized error is not
    // encountered
    while (!this.webSocket && !unauthorizedError) {
      try {
        // Hostname and port from which UI was loaded can be determined from window object
        const { hostname, port } = window.location || {};

        // Open web socket connection to server
        this.webSocket = new WebSocket(
          // The web socket protocol and port are pre-configured in the UI javascript code bundle
          `${WEB_SOCKET_PROTOCOL}://${hostname}${port ? `:${port}` : ''}/${WEB_SOCKET_PATH}`
        );

        // Actions to take when web socket connection is opened
        this.webSocket.onopen = (event: Event) => { // eslint-disable-line no-unused-vars
          console.info('Web socket connection opened');

          // Try-catch needed here because error does not fall through to surrounding code
          try {
            // Send a start signal to server
            this.webSocket.send(JSON.stringify(generateWebSocketMessage('start', '')));
          } catch (error) {
            this.handleError('Web socket start signal error', error);
          }
        };

        // Actions to take when messages originating from server are received
        this.webSocket.onmessage = async (event: MessageEvent) => {
          // Message data is expected to be JSON
          const webSocketMessage: WebSocketMessage = JSON.parse(event.data);

          // Log for analysis
          console.debug('Web socket message received', webSocketMessage);

          // Call appropriate function based on web socket route
          if (webSocketMessage.action === STX_REQUESTS_WEB_SOCKET_ROUTE) {
            // Trigger all relevant child components to update their data from STX Requests table
            await this.triggerUpdateFromSTXRequests();
          } else if (webSocketMessage.action === SFR_CONFIG_WEB_SOCKET_ROUTE) {
            // Trigger all relevant child components to update their data from SFR Config table
            await this.triggerUpdateFromSFRConfig();
          } else if (webSocketMessage.action === SFR_LOG_WEB_SOCKET_ROUTE) {
            // Trigger all relevant child components to update their data from SFR Log table
            await this.triggerUpdateFromSFRLog();
          } else if (webSocketMessage.action === SECONDARY_CONFIG_WEB_SOCKET_ROUTE) {
            await this.triggerUpdateFromSecondaryConfig();
          } else if (webSocketMessage.action === SECONDARY_LOG_WEB_SOCKET_ROUTE) {
            await this.triggerUpdateFromSecondaryLog();
          } else {
            console.warn(`Unknown message action: ${webSocketMessage.action}`);
          }
        };

        // Actions to take when asynchronous error occurs
        this.webSocket.onerror = (event: ErrorEvent) => {
          console.error(event);
        };

        // Actions to take on web socket connection close
        this.webSocket.onclose = async (event: CloseEvent) => {
          console.info(`Web socket connection closed (${event.code}${event.reason ? `, ${event.reason}` : ''})`);

          // Get the current user session state
          const isLoggedIn = getLoggedInLocal();

          // Attempt to reconnect as long as user session state is not logged in
          if (isLoggedIn) {
            console.info('Attempting web socket reconnect...');

            // Clear the web socket property of this component first
            clearWebSocketLocal();

            // Then attempt to reconnect the web socket
            await openWebSocketLocal();
          }
        };
      } catch (error) {
        // Custom error handling behavior instead of boilerplate
        console.error('Web socket connect error', error);

        // If error is defined, get handle on response
        const response = error.response as Response;

        // In case of a 401, logout
        if (response && response.status === 401) {
          // Indicate a 401 Unauthorized error so that the retry loop exits
          unauthorizedError = true;

          // Set user session state as logged out on client
          this.setAsLoggedOut();

          // Upon 401 Unauthorized error, do server-side log out (but don't wait here on request)
          SessionService.logout('Unauthorized error - user has been signed out');

          // Display the logged out page
          this.showAsLoggedOut();
        } else {
          // This should induce a web socket connection retry
          this.webSocket = undefined;

          // Delay a certain amount of time before
          await delaySec(initDelaySec); // eslint-disable-line no-await-in-loop

          // Increase next delay time until cap
          if ((initDelaySec + 1) >= maxDelaySec) {
            initDelaySec = maxDelaySec;
          } else {
            initDelaySec += 1;
          }
        }
      }
    }
  };

  /**
   * Clears the web socket object
   */
  clearWebSocket = () => {
    this.webSocket = undefined;
  };

  /**
   * Get tenant
   */
  async loadTenant() {
    try {
      // Return value as user info model
      const currentTenant = await SISService.getTenant();

      // Must go through setState function in order for changes to reflect in UI
      this.setState({ currentTenant });

      // Log for analysis
      console.debug('currentTenant', currentTenant);
    } catch (error) {
      this.handleError('Tenant request error', error);
    }
  }

  /**
   * Get user
   */
  async loadUser() {
    try {
      // Return value as user info model
      const currentUser = await SISService.getUser();

      // Must go through setState function in order for changes to reflect in UI
      this.setState({ currentUser });

      // Debug level log
      console.debug(`Current user ID = ${currentUser.userId}`);
    } catch (error) {
      this.handleError('User request error', error);
    }
  }

  /**
   * Get STX status
   */
  async loadSTXStatus() {
    try {
      // Get STX status info
      const stxStatus = await STXService.getSTXStatus();

      // Must go through setState function in order for changes to reflect in UI
      this.setState({ stxStatus, stxStateWidgetLabel: stxStatus.labelStateOverride });
    } catch (error) {
      this.handleError('STX status request error', error);
    }
  }

  /**
   * Trigger all relevant child components to update their data from STX Requests table
   */
  async triggerUpdateFromSTXRequests() {
    // Destructure to get previous counter value
    const { updateSTXRequestsCounter: previousValue } = this.state;

    // Increment counter value in order to trigger a reaction in this and child components
    this.setState({ updateSTXRequestsCounter: previousValue + 1 });
  }

  /**
   * Trigger all relevant child components to update their data from SFR Config table
   */
  async triggerUpdateFromSFRConfig() {
    // Destructure to get previous counter value
    const { updateSFRConfigCounter: previousValue } = this.state;

    // Increment counter value in order to trigger a reaction in this and child components
    this.setState({ updateSFRConfigCounter: previousValue + 1 });
  }

  /**
   * Trigger all relevant child components to update their data from SFR Log table
   */
  async triggerUpdateFromSFRLog() {
    // Destructure to get previous counter value
    const { updateSFRLogCounter: previousValue } = this.state;

    // Increment counter value in order to trigger a reaction in this and child components
    this.setState({ updateSFRLogCounter: previousValue + 1 });
  }

  /**
   * Trigger all relevant child components to update their data from Secondary Reports Config table
   */
  async triggerUpdateFromSecondaryConfig() {
    // Destructure to get previous counter value
    const { updateSecondaryConfigCounter: previousValue } = this.state;

    // Increment counter value in order to trigger a reaction in this and child components
    this.setState({ updateSecondaryConfigCounter: previousValue + 1 });
  }

  /**
   * Trigger all relevant child components to update their data from Secondary Reports Log table
   */
  async triggerUpdateFromSecondaryLog() {
    // Destructure to get previous counter value
    const { updateSecondaryLogCounter: previousValue } = this.state;

    // Increment counter value in order to trigger a reaction in this and child components
    this.setState({ updateSecondaryLogCounter: previousValue + 1 });
  }

  /**
   * Close web socket connection to server
   */
  closeWebSocket() {
    // If web socket was created
    if (this.webSocket) {
      // Send "end" message only if web socket is in an OPEN state
      // Note: if in a CONNECTING, CLOSING or CLOSED state, attempting to send a message would
      //       throw an error
      if (this.webSocket.readyState === this.webSocket.OPEN) {
        try {
          // Send end signal to server
          this.webSocket.send(JSON.stringify(generateWebSocketMessage('end', '')));
        } catch (error) {
          // Not high priority enough to alert user, so we just use console.error here
          console.error('Web socket end signal error', error);
        }
      }

      // Close the connection (uses default code, reason)
      try {
        this.webSocket.close();
      } catch (error) {
        // Not high priority enough to alert user, so we just use console.error here
        console.error('Web socket close error', error);
      }
      // Set web socket object to undefined so this block doesn't repeat unnecessarily
      this.webSocket = undefined;
    }
  }

  /**
   * Display page as if user is logged out
   */
  showAsLoggedOut() {
    const isShowingAsLoggedIn = false;
    this.setState({ isShowingAsLoggedIn });
  }

  /**
   * Render function
   */
  render(): ReactNode {
    // Destructing Assignment (in order to pass lint rule react/destructuring-assignment)
    const {
      isShowingAsLoggedIn,
      userNavigationData,
      currentTenant,
      currentUser,
      navigateToUrl,
      navigationData,
      stxStateWidgetLabel,
      stxStatus,
      updateSTXRequestsCounter,
      updateSFRConfigCounter,
      updateSFRLogCounter,
      updateSecondaryConfigCounter,
      updateSecondaryLogCounter
    } = this.state;

    // The JSX code below is converted into React.createElement() calls by the compiler because of
    // the jsx setting in tsconfig.json
    return (
      <div className="pds-app">
        { /* Top Header */ }
        <div className="pds-app-header-bar">
          <header>
            <Link
              to={ RTE_DASHBOARD }
              className="pds-powerschool-logo"
              id="psf-logo-navigation"
            >
              <pds-icon name="logo-powerschool-p-inverse" />
              <div className="pds-logo-text">
                <span className="pds-logo-text-primary">PowerSchool Compliance Cloud</span>
              </div>
            </Link>
          </header>
          {
            isShowingAsLoggedIn && (
              <div className="pds-app-actions">
                <PdsUserMenu
                  userNavigation={ userNavigationData }
                  user={ currentUser }
                  name="psf-user-menu-name"
                  className="psf-user-menu"
                />
              </div>
            )
          }
        </div>

        { /* Left Navigation Menu */ }
        {
          isShowingAsLoggedIn && (
            <div className="pds-app-nav-container">
              <PdsAppNav navigation={ navigationData } />
            </div>
          )
        }

        { /* Main Content */ }
        {
          isShowingAsLoggedIn ? (
            <AppRoutes
              handleError={ this.handleError }
              handleSuccess={ this.handleSuccess }
              navigateToUrl={ navigateToUrl }
              stxStateWidgetLabel={ stxStateWidgetLabel }
              stxStatus={ stxStatus }
              currentTenant={ currentTenant }
              currentUser={ currentUser }
              updateSTXRequestsCounter={ updateSTXRequestsCounter }
              updateSFRConfigCounter={ updateSFRConfigCounter }
              updateSFRLogCounter={ updateSFRLogCounter }
              updateSecondaryConfigCounter={ updateSecondaryConfigCounter }
              updateSecondaryLogCounter={ updateSecondaryLogCounter }
            />
          ) : (
            <LoggedOut />
          )
        }
      </div>
    );
  }
}

/**
 * Add functional component layer around base in order to access the useNavigate hook, which is
 * otherwise not accessible from stateful class components. Thanks a lot, ReactJS.
 *
 * @constructor
 */
export function MainWithNavigation() {
  const navigate = useNavigate();
  return <MainBase navigate={ navigate } />;
}

/**
 * Add another functional component layer in order to add surrounding router context required by
 * the useNavigate hook.
 *
 * @constructor
 */
export function Main() {
  return (
    <Router>
      <MainWithNavigation />
    </Router>
  );
}
