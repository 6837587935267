import axios, { AxiosInstance } from 'axios';
import { ENV } from '../const';

/**
 * Request Interceptor
 */
axios.interceptors.request.use(
  // I can do any modifications to the request here
  (request) => request,
  (err) => Promise.reject(err)
);

/**
 * Response Interceptor
 */
axios.interceptors.response.use(
  (response) => {
    // I have access to response object here and perform any additional validations here
    // For Example: In case the server returns 401 or 403, I can redirect user to login page
    if (response.status === 401 || response.status === 402) {
      return response;
    }

    return response;
  },
  (err) => Promise.reject(err)
);

/**
 * This will be our base util for all HTTP API calls. We import this httpClient in our
 * service/component files.
 */
const httpClient: AxiosInstance = axios.create({
  baseURL: ENV.baseUrl,
  headers: { 'content-type': 'application/json' }
});

export default httpClient;
